<!--start dialog template -->
<template>
  <v-row justify="center">
    <v-dialog
      :value="userDialogOpen"
      persistent
      max-width="600px"
      :scrollable="false"
    >
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>
            <span class="headline">{{ title }} User</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <eExamTree :setActive="setActive" :selected="selected" />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <DialogButtons
            :close="close"
            :save="find"
            :loading="loading"
            :valid="valid"
            :title="title"
          />
        </v-card-actions>
        <!--<pre>{{ formData }}</pre>-->
      </v-card>
    </v-dialog>
  </v-row>
</template>
<!--end dialog-->

<script>
export default {
  name: "UserDialog",
  props: {
    loading: {
      type: Boolean,
      required: false,
    },

    closeDialog: {
      type: Function,
      required: true,
    },
    search: {
      type: Function,
      required: true,
    },
    userDialogOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  name: "searchUserDialog",
  data: () => ({
    valid: true,

    location: {},
  }),
  methods: {
    find() {
      this.search(this.selected);
    },

    close() {
      this.closeDialog();
      this.location = {};
    },

    setActive(selection) {
      //console.log('selected', selection)
      this.location = selection.length > 0 ? selection[0] : { name: null };
    },
  },
  computed: {
    selected() {
      //console.log(this.location)
      return this.formData &&
        this.formData.location &&
        Object.keys(this.location).length === 0
        ? this.formData.location
        : this.location;
    },
  },
  mounted() {},
  watch: {},
};
</script>
