<!--start dialog template -->
<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent width="700px" scrollable>
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>
            <span class="headline">{{ title }} User</span>
          </v-toolbar-title>
        </v-toolbar>
        <perfect-scrollbar>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid">
                <v-row>
                  <v-col cols="12" lg="4" md="4" sm="12" dense>
                    <v-text-field
                      label="First Name"
                      v-model.trim="formData.first_name"
                      required
                      :rules="[isValid.required]"
                      v-uppercase
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4" md="4" sm="12">
                    <v-text-field
                      label="Middle Name"
                      v-model.trim="formData.middle_name"
                      required
                      v-uppercase
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4" md="4" sm="12">
                    <v-text-field
                      label="Surname"
                      v-model.trim="formData.surname"
                      required
                      :rules="[isValid.required]"
                      v-uppercase
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6" md="6" sm="12">
                    <v-text-field
                      label="Email"
                      v-model="formData.email"
                      required
                      :rules="[isValid.required, isValid.existsEmail]"
                      :loading="isValidatingData"
                      @change.native="checkExistsEmails($event.target.value)"
                      :messages="existsEmailMessage"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="12">
                    <v-select
                      label="Gender"
                      :items="genders"
                      :item-text="'name'"
                      v-model="formData.gender"
                      return-object
                      required
                      v-uppercase
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6" md="6" sm="12">
                    <DatePicker
                      v-model="formData.birthdate"
                      :label="'Birth Date'"
                      :maxDate="$mixins.maxDate(18)"
                      :minDate="$mixins.minDate(60)"
                      :yearPicker="true"
                    />
                  </v-col>

                  <v-col cols="12" lg="6" md="6" sm="12">
                    <v-autocomplete
                      label="staff Category"
                      :items="staffCategories"
                      :item-text="'name'"
                      v-model="formData.teaching_category"
                      return-object
                      required
                      v-uppercase
                      :rules="[isValid.required]"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6" md="6" sm="12">
                    <v-text-field
                      label="Cheque Number"
                      v-model="formData.cheque_number"
                      required
                      :rules="[isValid.required, isValid.existsCheckNo]"
                      :loading="isValidatingData"
                      @change.native="checkExistsCheckNo($event.target.value)"
                      :messages="existsCheckNoMessage"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="12">
                    <v-text-field
                      label="NIDA ID"
                      v-model="formData.NIN"
                      required
                      :rules="[isValid.required, isValid.existsNida]"
                      :loading="isValidatingData"
                      @change.native="checkExistsNida($event.target.value)"
                      :messages="existsNidaMessage"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <DualSelects
                      :items="roles"
                      :title="'Select Roles'"
                      :label="'Filter Roles...'"
                      :columnName="'name'"
                      :filter-function="search"
                      v-model="formData.roles"
                      :is-open="dialog"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12" v-if="!isHOS">
                    <eExamTree
                      :setActive="setActive"
                      :selected="selected"
                      :location="formData.location"
                      @location="formData.location = $event"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </perfect-scrollbar>
        <v-card-actions>
          <v-spacer></v-spacer>
          <DialogButtons
            :close="close"
            :save="add"
            :loading="loading"
            :valid="valid"
            :title="title"
          />
        </v-card-actions>
        <!--<pre>{{ formData }}</pre>-->
      </v-card>
    </v-dialog>
  </v-row>
</template>
<!--end dialog-->

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
    searchOptions: {
      type: Function,
      required: true,
    },

    closeDialog: {
      type: Function,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
    genders: {
      type: Array,
      required: true,
    },

    staffCategories: {
      type: Array,
      required: true,
    },
  },
  name: "UserDialog",
  data: () => ({
    valid: true,

    existsEmailMessage: "",
    existsCheckNoMessage: "",
    existsNidaMessage: "",
    isValidating: false,
    isValid: {
      name: (v) => !!v || " Name required",
      required: (v) => !!v || " Field required",
      /// (v) => /.+[^#<>:;,?""*|/]+/.test(v) || 'Invalid Character',

      existsEmail: () => true || this.existsEmailMessage,
      existsCheckNo: () => true || this.existsCheckNoMessage,
      existsNida: () => true || this.existsNidaMessage,
    },
    location: {},
  }),
  methods: {
    add() {
      this.formData.location =
        this.isHOS === true ? this.user.location : this.selected;
      let payload = { ...this.formData };
      payload.photo = this.formData.photo
        ? this.formData.photo.toString().split(",")[1]
        : null;
      payload.completion_percentage = 0;

      this.save(payload);
    },

    close() {
      this.closeDialog();
      this.location = {};
    },
    search(data) {
      this.searchRoles(data);
    },

    setActive(selection) {
      //console.log('selected', selection)
      this.location = selection.length > 0 ? selection[0] : { name: null };
    },

    async checkExistsEmails(data) {
      this.isValidating = true;
      let payload = {
        email: data.toLowerCase(),
      };
      this.$mixins
        .checkExists("/api/v1/users/exists", payload)
        .then((response) => {
          this.isValidating = false;
          if (response.exists) {
            this.isValid.existsEmail = false;
            this.existsEmailMessage = `Email address already taken at ${response.response?.location?.name}`;
          } else {
            this.isValid.existsEmail = true;
            this.existsEmailMessage = "";
          }
        });
    },

    async checkExistsCheckNo(data) {
      this.isValidating = true;
      let payload = {
        cheque_number: data.toLowerCase(),
      };
      this.$mixins
        .checkExists("/api/v1/users/exists", payload)
        .then((response) => {
          this.isValidating = false;
          if (response.exists) {
            this.isValid.existsCheckNo = false;
            this.existsCheckNoMessage = `Cheque Number already in use at ${response.response?.location?.name}`;
          } else {
            this.isValid.existsCheckNo = true;
            this.existsCheckNoMessage = "";
          }
        });
    },

    async checkExistsNida(data) {
      this.isValidating = true;
      let payload = {
        nida: data.toLowerCase(),
      };
      this.$mixins
        .checkExists("/api/v1/users/exists", payload)
        .then((response) => {
          this.isValidating = false;
          if (response.exists) {
            this.isValid.existsNida = false;
            this.existsNidaMessage = `NIDA ID already in use at ${response.response?.location?.name}`;
          } else {
            this.isValid.existsNida = true;
            this.existsNidaMessage = "";
          }
        });
    },
  },
  computed: {
    title() {
      return this.formData.id ? "Update" : "Create";
    },
    selected() {
      return this.formData &&
        this.formData.location &&
        Object.keys(this.location).length === 0
        ? this.formData.location
        : this.location;
    },

    isHOS() {
      return this.user?.location?.level?.id === 5 ? true : false;
    },

    isValidatingData() {
      return this.isValidating ? true : false;
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped>
.ps {
  height: 600px;
}
</style>
